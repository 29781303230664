<template>
  <div
    class="popup_wrap"
    style="width:920px; height:600px;"
  >
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <form id="searchForm" @submit.prevent>
        <h1 class="page_title">RF DATA {{ $t('msg.CMBA380.031') }}</h1><!-- RF DATA 정보 -->
        <div class="content_box">
          <div class="content_box"> {{ $t('msg.CART010G010.066') }}</div>
          <div class="content_box">
            <table class="tbl_search">
              <colgroup>
                <col width="100px">
                <col width="100px">
                <col width="100px">
                <col width="100px">
                <col width="100px">
                <col width="100px">
                <col width="100px">
              </colgroup>
              <tbody>
                <tr>
                  <td v-for="(row,i) in cntrListFrt" :key="i">
                    <div>
                      <a v-if="row.iotYn === 'Y'" href="javascript:void(0)" class="_link" @click.prevent="cntrInfoSearch(row.cntrNo)">{{ row.cntrNo }} </a>
                      <span v-else>{{ row.cntrNo }} </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td v-for="(row,i) in cntrListSnd" :key="i">
                    <div>
                      <a v-if="row.iotYn === 'Y'" href="javascript:void(0)" class="_link" @click.prevent="cntrInfoSearch(row.cntrNo)">{{ row.cntrNo }} </a>
                      <span v-else>{{ row.cntrNo }} </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td v-for="(row,i ) in cntrListThd" :key="i">
                    <div>
                      <a v-if="row.iotYn === 'Y'" href="javascript:void(0)" class="_link" @click.prevent="cntrInfoSearch(row.cntrNo)">{{ row.cntrNo }} </a>
                      <span v-else>{{ row.cntrNo }} </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td v-for="(row,i ) in cntrListfour" :key="i">
                    <div>
                      <a v-if="row.iotYn === 'Y'" href="javascript:void(0)" class="_link" @click.prevent="cntrInfoSearch(row.cntrNo)">{{ row.cntrNo }} </a>
                      <span v-else>{{ row.cntrNo }} </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pr5">
              <p v-if="cntrList.length > 27">
                <select v-model="selectCntrNo" @change.prevent="cntrInfoSearch(selectCntrNo)">
                  <option v-for="(cvo, cidx) in cntrListOther" :key="'cntrNo_' + cidx" :value="cvo.cntrNo" :disabled="cvo.iotYn === 'N'">{{ cvo.cntrNo }}</option>
                </select>
              </p>
            </div>
          </div>
          <br>
          <div v-if="vslInfo.cntrLoInfoList.length > 0 || vslInfo.vslLocation.length > 0" style="height:400px;" class="map_wrap">
            <rf-location-info
              :parent-info="vslInfo"
            />
          </div>
          <div v-else style="height:200px;" class="map_wrap content_box beforetab">
            <div class="">
              {{ $t('msg.CSCT060G030.022') }}
            </div>
          </div>
        </div>

        <div v-if="vslInfo.cntrLoInfoList.length > 0" class="content_box">
          <h2 class="content_title"></h2>
          <div class="form_wrap t2">
            <rf-data-grid-pop ref="rfDataGridPop" :parent-info="rfDataGridInfo"></rf-data-grid-pop>
          </div>
        </div>
        <div v-else style="height:200px;" class="map_wrap content_box">
          <div class="">
            {{ $t('msg.CSCT060G030.022') }}
          </div>
        </div>

        <div v-if="vslInfo.cntrLoInfoList.length > 0" class="content_box">
          <div>
            <span class="ml_auto mt20">
              <a class="button md print" href="javascript:none(0)" @click.prevent="graphScreenShot"><span class="btn_icon print"></span>인쇄</a>
            </span>
            <a class="button blue sh float_right" href="javascript:void(0)" @click.prevent="graphSearch()">{{ $t('msg.ONEX010T010.010') }}<!-- 검색 --></a>

            <div class="form_wrap t2 float_right" style="margin-right: 5px;">
              <e-date-range-picker-simple
                id="fromDtToDt"
                :sdate="searchParams.fromDt"
                :edate="searchParams.toDt"
                @change="changeDateRange"
              />
            </div>
          </div>
          <h2 class="content_title"></h2>
          <!-- <calc-issue-limit></calc-issue-limit> -->
          <div style="margin-top: 35px;">
            <rf-data-graph ref="rfDataGragh" :parent-info="rfDataGraghInfo"></rf-data-graph>
          </div>
        </div>
        <div v-else style="height:200px;" class="map_wrap content_box">
          <div class="">
            {{ $t('msg.CSCT060G030.022') }}
          </div>
        </div>
        <!-- 닫기 -->
        <div class="mt10 text_center">
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close')"
          >
            {{ $t('msg.SCHD010P040.046') }}
          </a>
        </div>
        <win-layer-pop class="pdf_download_pop" style="z-index: 99999;">
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            :parent-info="popupParams"
          />
        </win-layer-pop>
      </form>
    </div>
  </div>
</template>

<script>

import schedule from '@/api/rest/schedule/schedule'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import vslScheduleInfo2 from '@/api/rest/schedule/vslScheduleInfo2'
import cargo from '@/api/rest/trans/cargo'
import rfDg from '@/api/rest/trans/rfDg'
import commons from '@/api/services/commons'
import { rootComputed } from '@/store/helpers'
import { rfDataGmt } from '@/api/rf-gmt-info'
import moment from 'moment'

export default {
  name: 'RfDataInfoPop',
  components: {
    'rf-location-info': () => import('@/pages/trans/popup/RfLocationInfo'),
    'close-info': () => import('@/components/trans/CloseInfo.vue'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    ChangeNoticePop: () => import('@/pages/pdf/popup/ChangeNoticePop'),
    'rf-data-graph': () => import('@/pages/trans/popup/RfDataGraph'),
    'rf-data-grid-pop': () => import('@/pages/trans/popup/RfDataGridPop'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          cntrList: [],
          selectCntrList: [],
          detailParams: {}
        }
      }
     }
  },
  data: function () {
    return {
      // PDF 다운로드 팝업 컴포넌트
      customComponent: null,
      // 선박 위치 유무
      isVslLocation: false,
      // 선박 위치 호출 데이터
      vslInfo: {
        cntrLoInfoList: [],
        maxDtm: '',
        vslLocation: [],
        arrVslVoy: []
      },
      // 선박 지연 여부
      popupParams: {
        items: []
      },
      searchParams: {
        fromDt: '',
        toDt: ''
      },
      selectCntrNo: '',
      selectCntrList: [],
      cntrList: [],
      cntrListFrt: [],
      cntrListSnd: [],
      cntrListThd: [],
      cntrListfour: [],
      cntrListOther: [],

      convertList: [],
      searchFlag: 'N',

      // 컨테이너 검색조건
      params: {
        dtKnd: 'BL',
        blNo: ''
      },
      // 컨테이너 상세 정보 관련 데이터
      //상세 검색 정보
      detailParams: {
        blNo: '',
        bkgNo: '',
        cntrNo: '',
        cntrTypCd: '',
        tsYn: '',
        rail: '',
        railCnt: 0,
        //상세에서 처음에 보여줄 내륙정보 셋팅 (마지막 LEG ETD/ETA 기준)
        inlandEta: '',
        inlandEtd: ''
      },
      tsYn: '',
      //컨테이너 detail 관련 정보
      detailList: [],
      detailDateList: [],
      //마감 정보, 내륙 정보
      inlandList: [],
      cntrListForDetail: [],

      rfDataGraghInitParams: {},
      cntrLoInfoList: [],
      cntrGrInfoList: [],
      rfDataGraghInfo: {
        graphDataList: []
      },
      rfDataList: [],

      rfDataGridInfo: {
        rfDataGridInfoList: [],
        traceDetail: [],
        inlandList: []
      },

      commonCodes: [],
      step01: {}, // Empty Container Pick up
      step02: {}, // Port of Loading
      step03: {}, // On Board
      step04: {}, // Port of Discharging
      step05: {}, // Gate Out
      step06: {}, // Return
      step99: {},

      ctrInfoList: [],

      step01MvntDtm: '',
      step02MvntDtm: '',
      step03MvntDtm: '',
      step04MvntDtm: '',
      step05MvntDtm: '',
      step06MvntDtm: '',
      step99MvntDtm: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      this.init()
    }
  },
  async created () {
    await this.init()
    // console.log('created parent @@@@@ ', this.parentInfo)
  },
  mounted () {
  },
  methods: {
    async init () {
      // 그래프 날짜 세팅
      this.searchParams.fromDt = this.$ekmtcCommon.getAddDay('', -7).substring(0, 8)
      this.searchParams.toDt = (this.$ekmtcCommon.getToday()).replaceAll('-', '')

      // GMT 기준 시간 가져오기
      this.ctrInfoList = await rfDataGmt.ctrInfoList

      let iotYnCntrNo = []
      let paramCntrNo = ''

      // 컨테이너 리스트 세팅
      if (this.parentInfo.cntrList.length > 0) {
        this.selectCntrList = this.parentInfo.selectCntrList

        for (let item of this.parentInfo.cntrList) {
          await rfDg.getRfdataCnt(item.cntrNo)
            .then(res => {
              // console.log(res)

              const iotYn = res.data.iotCnt > 0 ? 'Y' : 'N'
              this.cntrList.push({
                cntrNo: item.cntrNo,
                blNo: item.blNo,
                bkgNo: item.bkgNo,
                tsYn: item.tsYn,
                cntrTypCd: item.cntrTypCd,
                iotYn: iotYn
              })

              if (iotYn === 'Y') {
                iotYnCntrNo.push(item.cntrNo)
              }
            })
        }

        if (this.$ekmtcCommon.isNotEmpty(iotYnCntrNo[0])) {
          paramCntrNo = iotYnCntrNo[0]
        } else {
          paramCntrNo = this.parentInfo.detailParams.cntrNo
        }

        this.selectCntrNo = paramCntrNo

        await this.cntrInfoSearch(paramCntrNo)
      } else {
        // 컨티이너 검색 조건 blNo
        if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.blNo)) {
          this.params.blNo = this.parentInfo.blNo

          await this.getCargoCntrList()
        }

        if (this.cntrList.length > 0) {
          await this.cntrInfoSearch(this.selectCntrNo)
        }
      }

      // 렌더링 이후에 필요한 작업 수행
      await this.setCntrList()

      // 무브먼트 데이터 세팅
      let trackingList = this.$props.parentInfo.detailList
      if (trackingList.length > 0) {
        for (let i = 0; i < trackingList.length; i++) {
          if (trackingList[i].rk === 1) {
            // Empty Container Pick up
            this.step01 = trackingList[i]
          } else if (trackingList[i].rk === 3) {
            // Port of Loading
            this.step02 = trackingList[i]
          } else if (trackingList[i].rk === 5) {
            // On Board
            this.step03 = trackingList[i]
          } else if (trackingList[i].rk === 8) {
            // Port of Discharging
            this.step04 = trackingList[i]
          } else if (trackingList[i].rk === 10) {
            // Gate Out
            this.step05 = trackingList[i]
          } else if ((trackingList[i].rk === 11 || (trackingList[i].rk === 3 && trackingList[i].rkNext === undefined)) && !this.$ekmtcCommon.isEmpty(this.step06)) {
            // Return
              if (this.$ekmtcCommon.isEmpty(this.step06.mvntDt) || this.step06.mvntDt < trackingList[i].mvntDt) {
                this.step06 = trackingList[i]
              }
          } else {
            this.step99 = trackingList[i]
          }
        }
      }

      // 무브먼트 시간 gmt 변환
      await this.movementTimeToGmt()

      //Event Cd 가져오기
      const arrCdId = ['WB014']
      this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)
    },
    async getCargoCntrList () {
      const TH = this
      const params = TH.params

      // if (this.checkValidationAll()) {
        await cargo.getContainerList(params).then(res => {
          const data = res.data
          const cntrList = data.cntrList

          // console.log('getContainerList cntrList @@@@ ', cntrList)

          TH.cntrList = []
          TH.cntrListForDetail = []
          TH.cntrListForDetail = cntrList

          if (cntrList.length > 0) {
            //cntr가공
            TH.selectCntrNo = cntrList[0].cntrNo

            if (this.parentInfo.kind === 'working') {
              TH.selectCntrList.push(cntrList[0])
            }
          } else {
            TH.cntrList = []
            TH.convertList = []
            this.$ekmtcCommon.alertDefault(this.$t('msg.CART010G010.051')) //검색 결과가 없습니다.
          }

          TH.searchFlag = 'Y'
          // TH.setCntrList()
        })

        for (let item of this.cntrListForDetail) {
          await rfDg.getRfdataCnt(item.cntrNo)
            .then(res => {
              // console.log(res)

              const iotYn = res.data.iotCnt > 0 ? 'Y' : 'N'
              TH.cntrList.push({
                cntrNo: item.cntrNo,
                blNo: item.blNo,
                bkgNo: item.bkgNo,
                tsYn: item.tsYn,
                cntrTypCd: item.cntrTypCd,
                iotYn: iotYn
              })
            })
        }
    },
    async cntrInfoSearch (cntrNo) {
      // console.log('cntrInfoSearch cntrNo @@@@@ ', cntrNo)
      this.selectCntrNo = cntrNo

      await this.getCargoCntrDetail(cntrNo)

      await rfDg.getRfdata(cntrNo)
      .then(res => {
        const rfDataList = res.data.rfDataList
        const rfDataDtmMax = res.data.maxDtm

        // 가장 최근값
        this.vslInfo.maxDtm = rfDataDtmMax

        // 컨테이너 위치 호출을 위한 데이터 세팅(하위 컴포넌트로 내려보낼 데이터)
        this.setCntrLocationData(rfDataList)
      })
      .catch(err => {
        console.log(err)
      })
    },
    async getCargoCntrDetail (cntrNo) {
      console.log('######## getCargoCntrDetail #########')
      const TH = this
      let detailParams = {}
      let tmpBlNo = ''
      let tmpBkgNo = ''
      let tmpTsYn = ''
      let tmpCntrTypCd = ''
      let list = this.cntrList

      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.detailParams) && this.parentInfo.detailParams.cntrNo === cntrNo) {
        detailParams = this.parentInfo.detailParams
      } else {
          await list.forEach(item => {
            if (item.cntrNo === cntrNo) {
              tmpBlNo = item.blNo
              tmpBkgNo = item.bkgNo
              tmpTsYn = item.tsYn
              tmpCntrTypCd = item.cntrTypCd
            }
          })

          detailParams.dtKnd = this.parentInfo.detailParams.dtKnd

          // 상세정보 할당하기
          detailParams.blNo = tmpBlNo
          if (!tmpBkgNo.includes('<br>')) {
            detailParams.bkgNo = tmpBkgNo
            detailParams.strBkgNo = tmpBkgNo
          } else {
            detailParams.bkgNo = tmpBkgNo.split('<br>')[0]
            detailParams.strBkgNo = tmpBkgNo.split('<br>').join(';')
          }
          detailParams.cntrNo = cntrNo
          detailParams.tsYn = tmpTsYn
          detailParams.cntrTypCd = tmpCntrTypCd
      }

      // console.log('detaillParams @@@@@', detailParams)

      await cargo.getContainerDetail(detailParams).then(res => {
        // console.log('getContainerDetail @@@@@', res)

        // 초기화
        this.detailList = []
        this.detailDateList = []
        this.inlandList = []
        this.averageParams = {}
        this.dateFlag = ''
        this.rank = ''
        this.inlandEta = ''
        this.inlandEtd = ''

        // 할당
        this.detailList = res.data.trackingList
        this.detailDateList = res.data.dtVo
        this.dateFlag = res.data.dateFlag

        // trackingList의 가장 최신 rk 값으로 변경
        this.rank = this.detailList.length > 0 && this.$ekmtcCommon.isNotEmpty(this.detailList[0].rk) ? this.detailList[0].rk : res.data.rank
      })

      // 무브먼트 데이터 세팅
      let trackingList = this.detailList
      if (trackingList.length > 0) {
        for (let i = 0; i < trackingList.length; i++) {
          if (trackingList[i].rk === 1) {
            // Empty Container Pick up
            this.step01 = trackingList[i]
          } else if (trackingList[i].rk === 3) {
            // Port of Loading
            this.step02 = trackingList[i]
          } else if (trackingList[i].rk === 5) {
            // On Board
            this.step03 = trackingList[i]
          } else if (trackingList[i].rk === 8) {
            // Port of Discharging
            this.step04 = trackingList[i]
          } else if (trackingList[i].rk === 10) {
            // Gate Out
            this.step05 = trackingList[i]
          } else if ((trackingList[i].rk === 11 || (trackingList[i].rk === 3 && trackingList[i].rkNext === undefined)) && !this.$ekmtcCommon.isEmpty(this.step06)) {
            // Return
            if (this.$ekmtcCommon.isEmpty(this.step06.mvntDt) || this.step06.mvntDt < trackingList[i].mvntDt) {
              this.step06 = trackingList[i]
            }
          } else {
            this.step99 = trackingList[i]
          }
        }
      }

      // 무브먼트 시간 gmt 변환
      await this.movementTimeToGmt()

      this.detailFlag = 'Y'
    },
    async movementTimeToGmt () {
      let step01MvntDt = ''
      let step01MvntTm = ''
      let step02MvntDt = ''
      let step02MvntTm = ''
      let step03MvntDt = ''
      let step03MvntTm = ''
      let step04MvntDt = ''
      let step04MvntTm = ''
      let step05MvntDt = ''
      let step05MvntTm = ''
      let step06MvntDt = ''
      let step06MvntTm = ''

      // 무브먼트 데이터 GMT 변환
      if (this.step01 !== undefined && Object.keys(this.step01).length > 0) {
         step01MvntDt = moment(this.step01.mvntDt).format('YYYY-MM-DD')
         step01MvntTm = this.step01.mvntTm.substring(0, 2) + ':' + this.step01.mvntTm.substring(2, 4) + ':' + this.step01.mvntTm.substring(4)

        // step01MvntDtm = this.step01.mvntDt + this.step01.mvntTm

        this.step01MvntDtm = step01MvntDt + ' ' + step01MvntTm
        this.step01MvntDtm = this.localToGmt(this.step01MvntDtm, 'L')
      }

      if (this.step02 !== undefined && Object.keys(this.step02).length > 0) {
         step02MvntDt = moment(this.step02.mvntDt).format('YYYY-MM-DD')
         step02MvntTm = this.step02.mvntTm.substring(0, 2) + ':' + this.step02.mvntTm.substring(2, 4) + ':' + this.step02.mvntTm.substring(4)

        // step01MvntDtm = this.step01.mvntDt + this.step01.mvntTm

        this.step02MvntDtm = step02MvntDt + ' ' + step02MvntTm
        this.step02MvntDtm = this.localToGmt(this.step02MvntDtm, 'L')
      }

      if (this.step03 !== undefined && Object.keys(this.step03).length > 0) {
         step03MvntDt = moment(this.step03.mvntDt).format('YYYY-MM-DD')
         step03MvntTm = this.step03.mvntTm.substring(0, 2) + ':' + this.step03.mvntTm.substring(2, 4) + ':' + this.step03.mvntTm.substring(4)

        // step03MvntDtm = this.step03.mvntDt + this.step03.mvntTm

        this.step03MvntDtm = step03MvntDt + ' ' + step03MvntTm
        this.step03MvntDtm = this.localToGmt(this.step03MvntDtm, 'L')
      }

      if (this.step04 !== undefined && Object.keys(this.step04).length > 0) {
         step04MvntDt = moment(this.step04.mvntDt).format('YYYY-MM-DD')
         step04MvntTm = this.step04.mvntTm.substring(0, 2) + ':' + this.step04.mvntTm.substring(2, 4) + ':' + this.step04.mvntTm.substring(4)

        // step04MvntDtm = this.step04.mvntDt + this.step04.mvntTm

        this.step04MvntDtm = step04MvntDt + ' ' + step04MvntTm
        this.step04MvntDtm = this.localToGmt(this.step04MvntDtm, 'D')
      }

      if (this.step05 !== undefined && Object.keys(this.step05).length > 0) {
         step05MvntDt = moment(this.step05.mvntDt).format('YYYY-MM-DD')
         step05MvntTm = this.step05.mvntTm.substring(0, 2) + ':' + this.step05.mvntTm.substring(2, 4) + ':' + this.step05.mvntTm.substring(4)

        // step05MvntDtm = this.step05.mvntDt + this.step05.mvntTm

        this.step05MvntDtm = step05MvntDt + ' ' + step05MvntTm
        this.step05MvntDtm = this.localToGmt(this.step05MvntDtm, 'D')
      }

      if (this.step06 !== undefined && Object.keys(this.step06).length > 0) {
        // 무브먼트 데이터 GMT 변환
         step06MvntDt = moment(this.step06.mvntDt).format('YYYY-MM-DD')
         step06MvntTm = this.step06.mvntTm.substring(0, 2) + ':' + this.step06.mvntTm.substring(2, 4) + ':' + this.step06.mvntTm.substring(4)

        // step06MvntDtm = this.step06.mvntDt + this.step06.mvntTm
        this.step06MvntDtm = step06MvntDt + ' ' + step06MvntTm
        this.step06MvntDtm = this.localToGmt(this.step06MvntDtm, 'D')
      }
    },
    async setCntrLocationData (cntrData) {
      // console.log('setCntrLocationData cntrData @@@@@@@', cntrData)

      const TH = this
      const cntrDataList = cntrData
      const selectCntrList = this.selectCntrList
      let covArrVslNm = []
      let covParams = ''
      let vslLocation = []
      let arrVslNm = []
      let preVslData
      this.cntrLoInfoList = []

      selectCntrList.forEach((item, idx) => {
        arrVslNm = item.vslNm.split('%%')

        arrVslNm.forEach((vo, idx) => {
          covArrVslNm.push(vo.substring(2))
        })
      })

      covParams = covArrVslNm.join(':')

      let params = {
        covParams: covParams,
        blNo: selectCntrList[0].blNo
      }
      // console.log('covParams @@@@@ ', covParams)

      await rfDg.getRfVslLocation(params)
        .then(res => {
          if (res.data.rfVslLocationList.length > 0) {
            vslLocation = res.data.rfVslLocationList
            preVslData = vslLocation[vslLocation.length - 1]
          }

          // console.log('getRfVslLocation res @@@@ ', res)
          const arrEtd = selectCntrList[0].etd.split('%%')
          const arrEta = selectCntrList[0].eta.split('%%')

          const etd = (arrEtd[0].replaceAll('-', '')).replaceAll(':', '').split(' ').join('')
          const eta = (arrEta[arrEta.length - 1].replaceAll('-', '')).replaceAll(':', '').split(' ').join('')

          // 데이터 검색전 리셋
          this.vslInfo.vslLocation = []

          vslLocation.forEach((vitem, vdx) => {
            covArrVslNm.forEach((citem, cdx) => {
              if (vitem.vslNm.trim() === citem.split('/')[0].trim()) { // vslNm
                TH.vslInfo.vslLocation.push({
                  rteCd: vitem.rteCd,
                  voyNo: citem.split('/')[1], // voyNo
                  vslCd: vitem.vslCd,
                  vslNm: citem.split('/')[0],
                  polPortCd: vitem.polPortCd,
                  polTrmlCd: vitem.polTrmlCd,
                  // eta: eta,
                  // etd: etd,
                  eta: (arrEta[vdx].replaceAll('-', '')).replaceAll(':', '').split(' ').join(''),
                  etd: (arrEtd[vdx].replaceAll('-', '')).replaceAll(':', '').split(' ').join('')
                })
              }
            })
          })
        })

      let step06ConvDtm = ''
      let step01ConvDtm = ''
      let step02ConvDtm = ''

      let step01RfDataList = []
      let step06RfDataList = []

      // 그래프 데이터
      // IOT데이터 중 Gate out 이전 데이터 제외
      if (this.step01MvntDtm !== '') {
        for (let item of cntrDataList) {
          step01ConvDtm = ((item.rfDataDtm.substring(0, 10) + item.rfDataDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

          if (Number(step01ConvDtm) >= Number(this.step01MvntDtm)) {
            step01RfDataList.push(item)
          }
        }
      } else {
        if (this.step02MvntDtm !== '') {
          for (let item of cntrDataList) {
            step02ConvDtm = ((item.rfDataDtm.substring(0, 10) + item.rfDataDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

            if (Number(step02ConvDtm) >= Number(this.step02MvntDtm)) {
              step01RfDataList.push(item)
            }
          }
        } else {
          step01RfDataList = [...cntrDataList]
        }
      }

      // 컨테이너 반납 이후 데이터 제외
      if (this.step06MvntDtm !== '') {
        for (let cntr of step01RfDataList) {
          step06ConvDtm = ((cntr.rfDataDtm.substring(0, 10) + cntr.rfDataDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

          if (Number(step06ConvDtm) <= Number(this.step06MvntDtm)) {
            step06RfDataList.push(cntr)
          }
        }
      } else {
        step06RfDataList = [...step01RfDataList]
      }

      this.setCntrGraphData(step06RfDataList)

      // 지도 데이터
      for (let item of step06RfDataList) {
        await TH.cntrLoInfoList.push({
          cntrNo: item.astId,
          lttd: item.gpsLttd,
          lngtd: item.gpsLngtd,
          cntrDtm: item.rfDataDtm
        })
      }

      this.vslInfo.cntrLoInfoList = this.cntrLoInfoList
      this.vslInfo.trackingSteps = {
        step01: TH.step01,
        step02: TH.step02,
        step03: TH.step03,
        step04: TH.step04,
        step05: TH.step05,
        step06: TH.step06,
        step99: TH.step99,
        rank: TH.$props.parentInfo.rank !== '' ? TH.$props.parentInfo.rank : this.rank
      }

      this.vslInfo.movementGmt = {
        step01MvntDtm: this.step01MvntDtm,
        step02MvntDtm: this.step02MvntDtm,
        step03MvntDtm: this.step03MvntDtm,
        step04MvntDtm: this.step04MvntDtm,
        step05MvntDtm: this.step05MvntDtm,
        step06MvntDtm: this.step06MvntDtm,
        step99MvntDtm: this.step099MvntDtm
      }

      this.vslInfo.polCtrCd = this.selectCntrList[0].polCtrCd
      this.vslInfo.podCtrCd = this.selectCntrList[0].podCtrCd

      // 그리드 데이터
      this.rfDataGridInfo = {
        rfDataGridInfoList: step06RfDataList,
        step01: TH.step01,
        traceDetail: this.detailList,
        polCtrCd: this.selectCntrList[0].polCtrCd,
        podCtrCd: this.selectCntrList[0].podCtrCd
      }
    },
    setCntrGraphData (cntrData) {
      // console.log('setCntrGraphData @@@ ', cntrData)

      const TH = this
      const cntrGraphDataList = cntrData
      const searchParams = TH.searchParams

      let fromDt = searchParams.fromDt
      let toDt = searchParams.toDt

      this.cntrGrInfoList = []

      cntrGraphDataList.forEach((vo, idx) => {
          const arrRfDataDtm = vo.rfDataDtm.split('T')
          const rfDataDtm = Number(arrRfDataDtm[0].split('-').join(''))

          if (rfDataDtm >= fromDt && rfDataDtm <= toDt) {
            this.cntrGrInfoList.push({
              cntrDtm: vo.rfDataDtm,
              pctHum: vo.pctHum, // 습도
              tSet: vo.tset,
              trtn1: vo.trtn1
            })
          }
      })

      // 그래프 데이터 오름차순 정렬
      this.cntrGrInfoList.sort((f, s) => {
        let convF = ((f.cntrDtm.substring(0, 10) + f.cntrDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')
        let convS = ((s.cntrDtm.substring(0, 10) + s.cntrDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

        return Number(convF) - Number(convS)
      })

      this.$store.commit('trans/updateRfGraphDataList', this.cntrGrInfoList)

      // console.log('setCntrGraphData cntrGrInfoList @@@@@ ', this.cntrGrInfoList)
    },
    graphSearch () {
      this.cntrInfoSearch(this.selectCntrNo)
    },
    async setCntrList () {
      await this.cntrList.forEach((item, idx) => {
        if (idx >= 0 && idx < 7) {
          this.cntrListFrt.push(item)
        }

        if (idx >= 7 && idx < 14) {
          this.cntrListSnd.push(item)
        }

        if (idx >= 14 && idx < 21) {
          this.cntrListThd.push(item)
        }

        if (idx >= 21 && idx < 28) {
          this.cntrListfour.push(item)
        }

        if (idx >= 28) {
          this.cntrListOther.push(item)
        }
      })
    },
    changeDateRange (s, e) {
      // console.log('changeDateRange @@@@')
      this.searchParams.fromDt = s
      this.searchParams.toDt = e

      this.checkSearchValidation('fromDtToDt')
    },
    checkSearchValidationAll () {
      let isOk = true
      const prefix = ['fromDtToDt']

      prefix.forEach(el => {
        if (!this.checkSearchValidation(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkSearchValidation (id) {
      const searchParams = this.searchParams
      const frm = document.querySelector('#searchForm')
      const selector = frm.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

      switch (id) {
        case 'fromDtToDt':
          if (this.$ekmtcCommon.isEmpty(searchParams.fromDt) || this.$ekmtcCommon.isEmpty(searchParams.toDt)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (parseInt(searchParams.fromDt, 10) > parseInt(searchParams.toDt, 10)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
      }
      return isOk
    },
    //이벤트 명 가져오기
    getEventName (cd) {
      let catNm = 'X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
          this.commonCodes.WB014.forEach((item) => {
            if (item.cd === cd) {
                catNm = item.cdNm
            }
        })
      }
      return catNm
    },
    graphScreenShot () {
      this.$refs.rfDataGragh.screenPrint()
    },
    localToGmt (time, kind) {
      let date = new Date(time)
      let utcNow // 현재 시간을 utc로 변환한 밀리세컨드값
      let getTimezoneOffset

      let convCntrDtm = ''
      let polCtrCd = this.selectCntrList[0].polCtrCd
      let podCtrCd = this.selectCntrList[0].podCtrCd
      let polCtrGmt = ''
      let podCtrGmt = ''

      for (let gmtDiff of this.ctrInfoList) {
        if (gmtDiff.keyNm === polCtrCd) {
          polCtrGmt = Number(gmtDiff.info)
        }

        if (gmtDiff.keyNm === podCtrCd) {
          podCtrGmt = Number(gmtDiff.info)
        }
      }

      if (kind === 'L') {
        getTimezoneOffset = -(polCtrGmt * 60)
      }

      if (kind === 'D') {
        getTimezoneOffset = -(podCtrGmt * 60)
      }

      utcNow = date.getTime() + (getTimezoneOffset * 60 * 1000)
      date = new Date(utcNow)

      // 날짜/시간 비교위해 포맷 변환
      convCntrDtm = this.convertDate(date)

      return convCntrDtm
    },
    convertDate (date) {
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      var hours = ('0' + date.getHours()).slice(-2)
      var minutes = ('0' + date.getMinutes()).slice(-2)
      var seconds = ('0' + date.getSeconds()).slice(-2)

      var timeString = hours + ':' + minutes + ':' + seconds
      var dateString = year + '-' + month + '-' + day

      return ((dateString + timeString).replaceAll('-', '')).replaceAll(':', '')
    }
  }
}
</script>
<style scoped>
._link {
  color:#075bb9 !important;
}

a:hover {
  text-decoration: underline;
  /* color:blue; */
}

.popup_wrap {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
</style>
