// 국가 리스트
export const rfDataGmt = {
  // 국가별 정보
  ctrList: [
    { seq: '01', cdNm: 'KOREA' },
    { seq: '02', cdNm: 'JAPAN' },
    { seq: '03', cdNm: 'CHINA' },
    { seq: '04', cdNm: 'HONGKONG' },
    { seq: '05', cdNm: 'PHILIPPINES' },
    { seq: '06', cdNm: 'SINGAPORE' },
    { seq: '07', cdNm: 'MALAYSIA' },
    { seq: '08', cdNm: 'INDONESIA' },
    { seq: '09', cdNm: 'THAILAND' },
    { seq: '10', cdNm: 'VIETNAM' },
    { seq: '11', cdNm: 'BANGLADESH' },
    { seq: '12', cdNm: 'INDIA' },
    { seq: '13', cdNm: 'CAMBODIA' },
    { seq: '14', cdNm: 'RUSSIA' },
    { seq: '15', cdNm: 'SRILANKA' },
    { seq: '16', cdNm: 'U.A.E' },
    { seq: '17', cdNm: 'MYANMAR' },
    { seq: '18', cdNm: 'PAKISTAN' },
    { seq: '19', cdNm: 'SAUDI ARABIA' },
    { seq: '20', cdNm: 'OMAN' },
    { seq: '21', cdNm: 'KENYA' }
  ],
  ctrInfoList: [
    {
      key: '01',
      keyNm: 'KR',
      info: '9'
    },
    {
      key: '02',
      keyNm: 'JP',
      info: '9'
    },
    {
      key: '03',
      keyNm: 'CN',
      info: '8'
    },
    {
      key: '04',
      keyNm: 'HK',
      info: '8'
    },
    {
      key: '05',
      keyNm: 'PH',
      info: '8'
    },
    {
      key: '06',
      keyNm: 'SG',
      info: '8'
    },
    {
      key: '07',
      keyNm: 'MY',
      info: '8'
    },
    {
      key: '08',
      keyNm: 'ID',
      info: '7'
    },
    {
      key: '09',
      keyNm: 'TH',
      info: '7'
    },
    {
      key: '10',
      keyNm: 'VN',
      info: '7'
    },
    {
      key: '11',
      keyNm: 'BD',
      info: '6'
    },
    {
      key: '12',
      keyNm: 'IN',
      info: '5.5'
    },
    {
      key: '13',
      keyNm: 'KH',
      info: '7'
    },
    {
      key: '14',
      keyNm: 'RU',
      info: '4'
    },
    {
      key: '15',
      keyNm: 'LK',
      info: '5.5'
    },
    {
      key: '16',
      keyNm: 'AE',
      info: '4'
    },
    {
      key: '17',
      keyNm: 'MM',
      info: '6.5'
    },
    {
      key: '18',
      keyNm: 'PK',
      info: '5'
    },
    {
      key: '19',
      keyNm: 'SA',
      info: '3'
    },
    {
      key: '20',
      keyNm: 'OM',
      info: '4'
    },
    {
      key: '21',
      keyNm: 'KE',
      info: '3'
    }
  ]
}
